import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { RelativeDatePipe } from './pipe/relative-date.pipe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './page/landing-page/landing-page.component';
import { FaqComponent } from './page/faq/faq.component';
import { NoticeComponent } from './page/notice/notice.component';
import { IntroductionComponent } from './page/introduction/introduction.component';
import { CourseListComponent } from './page/course-list/course-list.component';
import { CourseViewerComponent } from './page/course-viewer/course-viewer.component';
import { CourseDetailComponent } from './page/course-detail/course-detail.component';
import { HeaderComponent } from './page/header/header.component';
import { FooterComponent } from './page/footer/footer.component';
import { IntroductionMapComponent } from './page/introduction-map/introduction-map.component';
import { LoginComponent } from './page/login/login.component';
import { UserBoardComponent } from './page/user-board/user-board.component';
import { BannerComponent } from './page/banner/banner.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDialogComponent } from './dialog/confirm-dialog/confirm-dialog.component';
import { AdminComponent } from './page/admin/admin.component';
import { ViewlogDialogComponent } from './dialog/viewlog-dialog/viewlog-dialog.component';
import { CertificateComponent } from './page/certificate/certificate.component';
import { MypageComponent } from './page/mypage/mypage.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    FaqComponent,
    NoticeComponent,
    IntroductionComponent,
    CourseListComponent,
    CourseViewerComponent,
    CourseDetailComponent,
    HeaderComponent,
    FooterComponent,
    IntroductionMapComponent,
    LoginComponent,
    UserBoardComponent,
    BannerComponent,

    RelativeDatePipe,

    ConfirmDialogComponent,
     AdminComponent,
     ViewlogDialogComponent,
     CertificateComponent,
     MypageComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CdkAccordionModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    
    MatCardModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressBarModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    // MatAutocompleteSelectedEvent,
    MatChipsModule,
    // MatChipInputEvent,
    MatStepperModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,

    ReactiveFormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
