import { Component } from '@angular/core';
import { AccountService } from 'src/app/service/account.service';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styleUrls: ['./mypage.component.scss']
})
export class MypageComponent {
  public step: string = 'room';
  public courseList: Array<any> = [];

  constructor(
    public account: AccountService,
  ) { 
    this.account.get_session().subscribe((res) => {
      if (res.status) {
        console.log(res);
      }
    });
  }
}
