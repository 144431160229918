<app-header></app-header>
<div class="content-wrapper">
  <div class="content-body">
    <h1>교육과정별 강의실</h1>

    <div class="lms-course-list-wrapper">
      <div class="lms-course-list-sidebar">
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'overview'}" (click)="step = 'overview'">교육과정 개요</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'list'}" (click)="step = 'list'">강의 목차</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'quiz'}" (click)="step = 'quiz'">실습 문제</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'download'}" (click)="step = 'download'">자료실</div>
        <!-- <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'qna'}" (click)="step = 'qna'">Q&A</div> -->
        <div *ngIf="currentCourse.progressPercent >= 100" class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'complete'}" (click)="step = 'complete'">수료증</div>
      </div>
      <div class="lms-course-list-gridview">
        <div *ngIf="step == 'overview'">
          <h1>교육과정 개요</h1>
          <div class="lms-progress-meta">
            <span>교육과정 진행률</span>
            <span class="lms-progress-percent">{{currentCourse.progressPercent}}%</span>
          </div>
          <div class="lms-progress-wrapper">
            <div class="lms-progress-active" [style.width.%]="currentCourse.progressPercent"></div>
          </div>
          <br>
          <app-banner bannerHeight="400px" [bannerImage]="currentCourse.thumbnail"></app-banner>
          <p>Donec eu suscipit leo. Morbi tristique nibh non mauris maximus interdum. Nulla erat quam, malesuada eget tincidunt non, porttitor eu metus. Donec vitae massa fermentum, molestie nunc ut, interdum arcu. Fusce condimentum tellus at tortor pretium, ac euismod tortor porttitor. Aliquam ac tincidunt nisi, ac sollicitudin neque. Phasellus maximus metus vitae elit euismod, in auctor quam lacinia. Donec sit amet tristique lacus. Etiam nec aliquam libero. Ut ante ligula, laoreet et eleifend eleifend, vestibulum vitae lorem.</p>
          <p>Aliquam suscipit efficitur tortor a imperdiet. Etiam facilisis enim ipsum, eget ullamcorper tellus placerat et. Nunc iaculis vitae ipsum non scelerisque. Fusce consectetur risus et urna sodales, a pulvinar leo venenatis. Fusce pharetra odio ipsum, et malesuada tortor congue sed. Curabitur condimentum id turpis ut fringilla. Curabitur porttitor commodo metus at facilisis. Aliquam mattis sapien vitae nibh ultrices, at pulvinar odio sollicitudin.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut elementum tortor, ut consequat erat. Ut in lectus enim. Donec nisi velit, pellentesque vitae tempus ut, dapibus in purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin viverra, elit non tempus tincidunt, neque tellus ornare nisl, at interdum augue purus eget ipsum. Ut id molestie lectus. Maecenas iaculis ex sem, efficitur vulputate mi scelerisque eu.</p>
        </div>
        <div class="lms-course-list-gridview" *ngIf="step == 'list'" style="padding: 0;">
          <div class="width-100" style="margin-bottom: 64px; padding-bottom: 64px; border-bottom: solid 1px #dedede;">
            <h1>강의 목차</h1>
            <mat-accordion class="example-headers-align" multi>
              <mat-expansion-panel class="padding-0" *ngFor="let section of courseSectionList">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{section.name}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="course-row" *ngFor="let course of section.data; let i = index;" [routerLink]="'/course-viewer/' + course.id + '/' + lectureId">
                  <span class="course-title">{{i+1}}강. {{course.title}}</span>
                  <span class="course-time">
                    <span class="course-time-processed" *ngIf="course.processedMin > 0">{{course.processedMin}}분 / </span>
                    {{course.min}}분
                  </span>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="lms-course-list-card" *ngFor="let course of courseList" [routerLink]="'/course-viewer/' + course.id + '/' + lectureId">
            <img [src]="course.thumbnail">
            <div class="lms-video-progress-wrapper">
              <div class="lms-video-progress-active" [style.width.%]="course.progressPercent"></div>
            </div>
            <div class="lms-course-list-card-meta">
              <div class="lms-course-list-card-title">
                {{course.title}}
                <span class="duration">{{course.time}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="width-100" *ngIf="step == 'quiz'">
          <mat-stepper *ngIf="!quizSubmit" linear="false" #stepper>
            <mat-step *ngFor="let quiz of quizList; let i = index;" [completed]="quiz.answer?.length > 0">
              <ng-template matStepLabel>Q{{i + 1}}</ng-template>
              <p>Q{{i + 1}}. 왼쪽 문장을 다듬어주세요.</p>
              <div class="flex-width-100">
                <div class="original-text-wrapper" [innerHTML]="quiz.question"></div>
                <textarea class="edit-text-wrapper" contenteditable="true" [(ngModel)]="quiz.answer"></textarea>
              </div>
              <div class="flex-width-100">
                <button *ngIf="i > 0" class="button secondary-button" matStepperPrevious>이전</button>
                <button class="button" matStepperNext>다음</button>
              </div>
            </mat-step>
            <mat-step label="제출">
              <p>정답을 제출하고 결과를 확인하시겠습니까?</p>
              <br><br><br><br><br><br><br><br><br><br><br><br><br>
              <div class="flex-width-100">
                <button class="button secondary-button" matStepperPrevious>이전</button>
                <button class="button" (click)="submit_quiz()">제출</button>
              </div>
            </mat-step>
          </mat-stepper>

          <mat-stepper *ngIf="quizSubmit" linear="false" #stepper>
            <mat-step *ngFor="let quiz of quizList; let i = index;" [completed]="quiz.answer?.length > 0">
              <ng-template matStepLabel>A{{i + 1}}</ng-template>
              <p><span style="color: #000; font-weight: bold;">제출한 답</span>과 <span style="color: #004e8f; font-weight: bold;">모범 답안</span>을 비교해보세요.</p>
              <div class="quiz-diff-button-wrapper">
                <div class="quiz-diff-button">
                  <input class="tgl tgl-light" id="cb1" type="checkbox" [(ngModel)]="quizViewType"/>
                  <label class="tgl-btn" for="cb1"></label>
                  <span>내가 쓴 문장과 모범답안 비교하기</span>
                </div>
              </div>
              <div class="flex-width-100">
                <div class="original-text-wrapper" style="color: #000 !important;" [innerHTML]="quiz.answer"></div>
                <div class="edit-text-wrapper" style="color: #004e8f !important;" [innerHTML]="quiz.llm"></div>
              </div>
              <div class="flex-width-100" style="margin-top: 12px; box-sizing: border-box;" *ngIf="quizViewType">
                <div class="diff-text-wrapper" [innerHTML]="quiz.c"></div>
              </div>
              <div class="flex-width-100" style="margin-top: 12px; box-sizing: border-box;" *ngIf="quizViewType">
                <div class="diff-text-wrapper2" [innerHTML]="quiz.d"></div>
              </div>
              <div class="flex-width-100">
                <button *ngIf="i > 0" class="button secondary-button" matStepperPrevious>이전</button>
                <button *ngIf="i + 1 < quizList.length" class="button" matStepperNext>다음</button>
              </div>
            </mat-step>
          </mat-stepper>
        </div>
        <div class="width-100" *ngIf="step == 'download'">
          <h1>자료실</h1>
          <table class="lms-board">
            <tr>
              <th></th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
            <tr style="background-color: #007eff20;">
              <td><span class="lms-board-chip">New</span></td>
              <td>온라인 교보재</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>1일 전</td>
            </tr>
            <tr>
              <td>5</td>
              <td>유고결석 처리 안내</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>3일 전</td>
            </tr>
            <tr>
              <td>4</td>
              <td>과제 제출 양식</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>2주 전</td>
            </tr>
            <tr>
              <td>3</td>
              <td>1차 퀴즈 안내</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>3주 전</td>
            </tr>
            <tr>
              <td>2</td>
              <td>강의 평가 방식</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>한 달 전</td>
            </tr>
            <tr>
              <td>1</td>
              <td>강의 개요</td>
              <td>{{currentCourse.lecturer}}</td>
              <td>한 달 전</td>
            </tr>
          </table>
        </div>
        <div class="width-100" *ngIf="step == 'qna'">
          <h1>Q&A</h1>
          <table class="lms-board">
            <tr>
              <th></th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
            <tr style="background-color: #007eff20;">
              <td><span class="lms-board-chip">New</span></td>
              <td>교보재는 어디서 구할 수 있나요?</td>
              <td>박찬규</td>
              <td>2일 전</td>
            </tr>
            <tr>
              <td>5</td>
              <td>예비군 참석으로 인해 강의를 듣지 못했습니다.</td>
              <td>조희찬</td>
              <td>6일 전</td>
            </tr>
            <tr>
              <td>4</td>
              <td>과제는 어디로 제출하면 되나요?</td>
              <td>김규진</td>
              <td>1주 전</td>
            </tr>
            <tr>
              <td>3</td>
              <td>퀴즈 결과는 어디서 확인할 수 있나요?</td>
              <td>이준범</td>
              <td>2주 전</td>
            </tr>
            <tr>
              <td>2</td>
              <td>강의 평가 방식이 어떻게 되나요?</td>
              <td>전해주</td>
              <td>한 달 전</td>
            </tr>
            <tr>
              <td>1</td>
              <td>주차별 진행 방식이 궁금합니다.</td>
              <td>서재진</td>
              <td>한 달 전</td>
            </tr>
          </table>
        </div>
        <div class="width-100" *ngIf="step == 'complete'">
          <div class="lms-course-list-title">
            <h1>수료증</h1>
            <span class="lms-course-list-button" (click)="print_certificate()">
              <label>인쇄</label>
            </span>
          </div>
          <br>          
          <app-certificate></app-certificate>
        </div>
      </div> 
    </div>
  </div>
</div>

<app-footer></app-footer>
