import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusService {
  public titlebar : string = '';
  public sidebar: boolean = true;
  public sidebarExpand : boolean = true;
  public isOpenDialog: boolean = false;

  public youtubeChat: Array<any> = [];
  public youtubeChatCallback: any = null;
  public youtubeURL: string | undefined = '';
  public youtubeVideoURL: any;
  public youtubePause: boolean = true;
  public youtubeCurrentTime: number = 0;
  public youtubeCurrentTimePivot: number = 0;
  
  constructor() { }
}
