import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from 'src/app/service/account.service';
import { LmsService } from 'src/app/service/lms.service';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent {
  public date = new Date();
  public hours = 0;

  constructor(
    private route: ActivatedRoute,
    private lmsService: LmsService,
    public account: AccountService,
  ) { 
    let id = +route.snapshot.params['id'] || undefined;
    
    if (id) {
      this.lmsService.get_lectureDetail(id).subscribe((res: any) => {
        if (res.status) {
          for (let course of res.data) {
            course.time = `${('00' + Math.floor(course.duration / 60)).slice(-2)}:${('00' + (course.duration % 60)).slice(-2)}`;
          }

          this.account.certificate = res.lecture;
          this.hours = Math.ceil(this.account.certificate.totalDuration / 3600);
        }
      });
    }
  }
}
