<app-header></app-header>
<div class="content-wrapper">
  <div class="content-body">
    <div class="lms-course-list-wrapper">
      <div class="lms-course-list-sidebar">
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'user'}" (click)="step = 'user'">사용자 관리</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'lecture'}" (click)="step = 'lecture'">강의 관리</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'notice'}" (click)="step = 'notice'">공지사항 관리</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'contact'}" (click)="step = 'contact'">문의내역 관리</div>
      </div>
      <div class="lms-course-list-gridview">
        <div class="width-100" *ngIf="step == 'user'">
          <h1>사용자 관리</h1>
          <p>사용자 클릭 시, 강의 시청 기록을 확인할 수 있습니다.</p>

          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
                No.
              </th>
              <td mat-cell *matCellDef="let element"> {{element.position}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                Name
              </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
                Weight
              </th>
              <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="symbol">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                Symbol
              </th>
              <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>

          <table class="lms-board">
            <tr>
              <th>이름</th>
              <th>이메일</th>
              <th>전화번호</th>
              <th>소속</th>
              <th>학회</th>
              <th>가입일</th>
              <th>마지막 접속일</th>
            </tr>
            <tr *ngFor="let user of userList" (click)="open_viewLog(user)">
              <td>{{user.name || '-'}}</td>
              <td>{{user.email || '-'}}</td>
              <td>{{user.phone || '-'}}</td>
              <td>{{user.company || '-'}}</td>
              <td>{{user.academy || '-'}}</td>
              <td>{{user.registered | relativeDate}}</td>
              <td *ngIf="user.lastAccess">{{user.lastAccess | relativeDate}}</td>
              <td *ngIf="!user.lastAccess">기록 없음</td>
            </tr>
          </table>
        </div>
        <div class="lms-course-list-gridview" *ngIf="step == 'lecture'" style="padding: 0;">
          <div class="lms-admin-title">
            <h1>강의 관리</h1>
            <span class="lms-primary-button">
              <label>강의 추가</label>
            </span>
          </div>
          <div class="lms-course-list-card" *ngFor="let course of courseList" [routerLink]="'/course-viewer/' + course.id">
            <img [src]="course.thumbnail">
            <div class="lms-course-list-card-meta">
              <div class="lms-course-list-card-title">
                {{course.title}}
                <span class="duration">{{course.time}}</span>
              </div>
              <div class="lms-course-list-card-icon">
                <i class="fa fa-pencil"></i>
                <i class="fa fa-trash"></i>
                </div>
            </div>
          </div>
        </div>
        <div class="width-100" *ngIf="step == 'notice'">
          <div class="lms-admin-title">
            <h1>공지사항 관리</h1>
            <span *ngIf="!notice.write && !notice.edit" class="lms-primary-button" (click)="notice.new()">
              <label>글쓰기</label>
            </span>
          </div>
          <div class="lms-board-write" *ngIf="notice.write">
            <input type="text" placeholder="공지 제목을 입력해주세요." [(ngModel)]="notice.title">
            <textarea rows="24" placeholder="공지 내용을 입력해주세요." [(ngModel)]="notice.content"></textarea>
            <label>첨부파일 등록</label>
            <input type="file">
            <br>
            <input type="checkbox" [(ngModel)]="notice.isFixed">
            <label>상단 공지로 등록</label>
            <div class="button-wrapper">
              <div class=button-container>
                <div class="button" (click)="notice.close()">
                  <label>취소</label>
                </div>
                <div class="button" (click)="notice.post()">
                  <label>작성</label>
                </div>
              </div>
            </div>
          </div>
          <div class="lms-board-write" *ngIf="notice.edit">
            <input type="text" placeholder="공지 제목을 입력해주세요." [(ngModel)]="notice.title">
            <textarea rows="24" placeholder="공지 내용을 입력해주세요." [(ngModel)]="notice.content"></textarea>
            <label>첨부파일 등록</label>
            <input type="file">
            <br>
            <input type="checkbox" [(ngModel)]="notice.isFixed">
            <label>상단 공지로 등록</label>
            <div class="button-wrapper">
              <div class=button-container>
                <div class="button" (click)="notice.close()">
                  <label>취소</label>
                </div>
                <div class="button delete-button" (click)="notice.delete()">
                  <label>삭제</label>
                </div>
                <div class="button" (click)="notice.modify()">
                  <label>수정</label>
                </div>
              </div>
            </div>
          </div>
          <table *ngIf="!notice.write && !notice.edit" class="lms-board">
            <tr>
              <th></th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
            </tr>
            <tr *ngFor="let item of notice.fixList" (click)="notice.open(item)" style="background-color: #007eff20;">
              <td><span class="lms-board-chip">Notice</span></td>
              <td>{{item.title}}</td>
              <td>관리자</td>
              <td>{{item.created_ts | relativeDate}}</td>
            </tr>
            <tr *ngFor="let item of notice.list" (click)="notice.open(item)">
              <td>{{item.id}}</td>
              <td>{{item.title}}</td>
              <td>관리자</td>
              <td>{{item.created_ts | relativeDate}}</td>
            </tr>
          </table>
        </div>
        <div class="width-100" *ngIf="step == 'contact'">
          <h1>문의내역 관리</h1>
          <table class="lms-board">
            <tr>
              <th></th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성일</th>
              <th>답변여부</th>
            </tr>
            <tr style="background-color: #007eff20;" *ngFor="let item of contactList">
              <td><span class="lms-board-chip">New</span></td>
              <td>{{item.content.substring(0, 20) + '...'}}</td>
              <td>{{item.email}}</td>
              <td>{{item.createdTime | relativeDate}}</td>
              <td>완료</td>
            </tr>
          </table>
        </div>
      </div> 
    </div>
  </div>
</div>

<app-footer></app-footer>
