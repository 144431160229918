<app-header></app-header>
<app-banner title="" titleSize="64px" titleColor="#fff" bannerHeight="560px" bannerImage="../../../assets/images/9.webp" overlayColor="#0000"></app-banner>
<div class="content-wrapper">
  <div class="content-body">
    <h1>Section 1</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id malesuada mauris. Donec malesuada dictum condimentum. Curabitur eget bibendum turpis. Nam et eros a massa eleifend pharetra. Morbi mollis mi nec ante facilisis maximus. Etiam dapibus dolor in diam placerat rutrum. Mauris rhoncus lorem et molestie condimentum.</p>
    <p>Sed accumsan vulputate ligula, sit amet imperdiet leo mollis sit amet. Nunc ullamcorper ligula lacus, eu convallis sapien feugiat at. Cras aliquam purus quis ex malesuada egestas. Proin a mi ut tellus porta dignissim. Aliquam a urna eu lacus faucibus lacinia sit amet ut elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aenean dapibus est a tincidunt convallis. Morbi eget ultrices metus. Pellentesque non venenatis quam, id viverra velit. Quisque erat justo, porttitor id orci vitae, bibendum feugiat odio. Curabitur porta eros neque, in tristique felis vehicula vel. Proin non magna at ipsum auctor laoreet eget sit amet lorem. Ut id justo mi. Quisque sem augue, consectetur quis odio vitae, ornare bibendum neque. Donec aliquet quam augue, in efficitur augue luctus at. Duis scelerisque tristique nulla, in pretium sapien auctor sit amet.</p>
    <p>Cras scelerisque ornare est, eu consectetur augue aliquet id. Donec sagittis viverra nisi id egestas. Integer ullamcorper neque vel augue sollicitudin, euismod laoreet arcu varius. Ut at gravida dolor. Nam ultrices, est facilisis efficitur maximus, tortor dolor aliquet magna, a euismod justo erat a sapien. Etiam euismod nisl quis lacus porttitor, sed porta purus egestas. Pellentesque in gravida dui. Integer consectetur pharetra metus, vel dignissim erat finibus eu. Etiam massa quam, bibendum id imperdiet ac, tincidunt at est. Nunc lacinia dignissim risus quis vulputate. Curabitur at urna neque. Sed imperdiet sed risus eget malesuada.</p>
    <p>Pellentesque luctus, ipsum eu egestas ullamcorper, velit sem tincidunt tellus, eget finibus mi lacus sed erat. Vivamus bibendum pulvinar odio, auctor ullamcorper ante pulvinar vitae. Phasellus feugiat, risus in consequat volutpat, odio libero aliquam nulla, a lobortis diam purus sed nisl. Vivamus vitae nisi ex. Maecenas interdum, risus a malesuada eleifend, nisi augue lacinia nunc, at pellentesque ante tellus vel ipsum. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut sodales aliquet lectus, sed tincidunt massa elementum id. Aliquam massa magna, sollicitudin a iaculis ut, tincidunt id purus. Curabitur vel placerat massa. Phasellus est nulla, interdum vel velit quis, blandit ultricies tellus. Nunc odio arcu, ornare scelerisque tellus et, aliquam faucibus felis. Aenean accumsan mi sapien. Aenean ultricies posuere diam non placerat. Integer congue volutpat velit sed elementum. Phasellus volutpat nisi neque, quis aliquam dui maximus quis. Maecenas tincidunt viverra felis id ultrices.</p>
    <div style="display: flex; width: 100%; margin: 90px 0 50px 0;">
      <img src="../../../assets/images/logo-big.png" style="width: 400px; margin: auto;">
      <img src="../../../assets/images/logo-snuh.png" style="width: 400px; margin: auto;">
    </div>
  </div>
</div>
<app-footer></app-footer>
