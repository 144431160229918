import { Component } from '@angular/core';

@Component({
  selector: 'app-introduction-map',
  templateUrl: './introduction-map.component.html',
  styleUrls: ['./introduction-map.component.scss']
})
export class IntroductionMapComponent {

}
