<app-header></app-header>
<div class="content-wrapper">
  <div class="content-body">
    <div class="lms-course-list-wrapper">
      <div class="lms-course-list-sidebar">
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'room'}" (click)="step = 'room'">내 강의실</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'info'}" (click)="step = 'info'">회원정보 수정</div>
        <div class="lms-course-list-sidebar-item" [ngClass]="{'active': step == 'password'}" (click)="step = 'password'">비밀번호 변경</div>
      </div>
      <div class="lms-course-list-gridview">
        <div  class="lms-course-list-gridview" *ngIf="step == 'room'" style="padding: 0;">
          <div class="lms-course-list-card" *ngFor="let course of courseList">
            <img [src]="course.thumbnail">
            <div class="lms-video-progress-wrapper">
              <div class="lms-video-progress-active" [style.width.%]="course.progressPercent"></div>
            </div>
            <div class="lms-course-list-card-meta">
              <div class="lms-course-list-card-title">
                {{course.title}}
                <span class="duration">{{course.time}}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="step == 'info'">
          <p>Donec eu suscipit leo. Morbi tristique nibh non mauris maximus interdum. Nulla erat quam, malesuada eget tincidunt non, porttitor eu metus. Donec vitae massa fermentum, molestie nunc ut, interdum arcu. Fusce condimentum tellus at tortor pretium, ac euismod tortor porttitor. Aliquam ac tincidunt nisi, ac sollicitudin neque. Phasellus maximus metus vitae elit euismod, in auctor quam lacinia. Donec sit amet tristique lacus. Etiam nec aliquam libero. Ut ante ligula, laoreet et eleifend eleifend, vestibulum vitae lorem.</p>
          <p>Aliquam suscipit efficitur tortor a imperdiet. Etiam facilisis enim ipsum, eget ullamcorper tellus placerat et. Nunc iaculis vitae ipsum non scelerisque. Fusce consectetur risus et urna sodales, a pulvinar leo venenatis. Fusce pharetra odio ipsum, et malesuada tortor congue sed. Curabitur condimentum id turpis ut fringilla. Curabitur porttitor commodo metus at facilisis. Aliquam mattis sapien vitae nibh ultrices, at pulvinar odio sollicitudin.</p>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ut elementum tortor, ut consequat erat. Ut in lectus enim. Donec nisi velit, pellentesque vitae tempus ut, dapibus in purus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Proin viverra, elit non tempus tincidunt, neque tellus ornare nisl, at interdum augue purus eget ipsum. Ut id molestie lectus. Maecenas iaculis ex sem, efficitur vulputate mi scelerisque eu.</p>
        </div>
        <div *ngIf="step == 'password'">
          <h1>비밀번호 변경</h1>
          <input placeholder="현재 비밀번호를 입력해주세요.">
          <input placeholder="변경할 비밀번호를 입력해주세요.">
          <input placeholder="변경할 비밀번호를 한 번 더 입력해주세요.">
        </div>
      </div> 
    </div>
  </div>
</div>

<app-footer></app-footer>
