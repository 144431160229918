import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';



import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';
import { AccountService } from 'src/app/service/account.service';
import { LmsService } from 'src/app/service/lms.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  public lectureId?: number = 0;
  public courseList: Array<any> = [];
  public currentCourse: any = {};
  public step: string = 'overview';
  public date = new Date();
  
  public quizViewType: boolean = false;
  public quizSubmit: boolean = false;
  public quizList: Array<any> = [
    {
      question: 'Q. 장기간 전자담배를 사용했을 때 부작용이나 해로운 점에는 무엇이 있을까요?<br>흡연자에서 암이나 동맥경화, 호흡기 질환 등의 해로움이 완전히 드러나기까진 20-30년 이상이 필요합니다. 액상형 전자담배는 시장에 나온 지 20년이 막 넘었고, 신제품들로 바꾸는 경우가 많아 오랫동안 액상형 전자담배를 피운 사람들을 대상으로 한 연구는 아직 부족합니다. 특히 대부분의 이전에 일반담배를 피웠거나 현재 함께 피우고 있기 때문에, 액상형 전자담배 만의 해로운 점을 구분하기 어렵습니다. 다만 단기 영향을 본 결과에서, 천식과 같은 호흡기 질환과 동맥경화증에서 부정적인 결과들이 꾸준히 발표되고 있습니다.',
      answer: 'Q. 장기간 전자담배를 사용했을 때 부작용이나 해로운 점에는 무엇이 있을까요?<br>흡연자에서 암이나 동맥경화, 호흡기 질환 등의 해로움이 완전히 드러나기까진 20-30년 이상이 필요합니다. 액상형 전자담배는 시장에 나온 지 20년이 막 넘었고, 신제품들로 바꾸는 경우가 많아 오랫동안 액상형 전자담배를 피운 사람들을 대상으로 한 연구는 아직 부족합니다. 특히 대부분의 이전에 일반담배를 피웠거나 현재 함께 피우고 있기 때문에, 액상형 전자담배 만의 해로운 점을 구분하기 어렵습니다. 다만 단기 영향을 본 결과에서, 천식과 같은 호흡기 질환과 동맥경화증에서 부정적인 결과들이 꾸준히 발표되고 있습니다.',
      llm: 'Q. 장기간 전자담배를 사용했을 때 부작용이나 해로운 점에는 무엇이 있을까요?\n전자담배의 부작용이나 해로운 점이 완전히 밝혀지기까지는 오랜 시간이 필요합니다. 일반적으로 흡연자의 경우 암, 동맥경화, 호흡기 질환 등의 심각한 질병이 나타나기까지는 20-30년 이상의 기간이 걸립니다. 액상형 전자담배는 시장에 출시된 지 약 20년 정도 되었으며, 사용자가 자주 신제품으로 교체하기 때문에 장기간 사용자의 건강에 미치는 영향에 대한 연구가 아직 충분하지 않습니다.\n특히, 많은 사용자가 과거에 일반 담배를 피웠거나 현재 일반 담배와 함께 전자담배를 사용하고 있어, 액상형 전자담배만의 해로운 점을 정확히 구별하기 어렵습니다. 그러나 단기간 사용에 따른 연구에서는 천식과 같은 호흡기 질환이나 동맥경화증과 관련된 부정적인 결과들이 지속적으로 보고되고 있습니다.',
      a: '',
      b: '',
    },{
      question: '액상형 전자담배(electronic cigarette, e-cigarette)는 일반담배(궐련)와 달리 배터리로 작동되는 전자 장치이다. 니코틴이 포함된 용액을 배터리로 가열해 발생하는 에어로졸을 마시는 방식이다. 2003년 중국에서 처음 출시되었고, 전세계적으로 유통되면서 청소년과 젊은 성인에서 큰 인기를 끌고 있다. 2017년부터 국내에서 판매되고 있는 궐련형 전자담배(아이코스, 글로, 릴 등)와 다른 제품이다.<br>액상형 전자담배를 사용하는 사람은 에어로졸을 폐로 흡입한다. 이때 옆에 있는 사람도 들이마시면서 간접적으로 노출될 수 있다.<br>액상형 전자담배도 담배의 중독 물질인 니코틴을 포함하고 있다. 국내외에서 대마초 등의 마약을 전자담배 기구를 이용해 흡입하는 사례가 늘어나고 있다.<br>액상형 전자담배는 보통 밝은 색상이며, 니코틴이 들어있는 용액에 다양한 향료(과일, 사탕, 멘솔, 민트 등)을 첨가하여 유혹하고 있다.',
      answer: '액상형 전자담배(electronic cigarette, e-cigarette)는 일반담배(궐련)와 달리 배터리로 작동되는 전자 장치이다. 니코틴이 포함된 용액을 배터리로 가열해 발생하는 에어로졸을 마시는 방식이다. 2003년 중국에서 처음 출시되었고, 전세계적으로 유통되면서 청소년과 젊은 성인에서 큰 인기를 끌고 있다. 2017년부터 국내에서 판매되고 있는 궐련형 전자담배(아이코스, 글로, 릴 등)와 다른 제품이다.<br>액상형 전자담배를 사용하는 사람은 에어로졸을 폐로 흡입한다. 이때 옆에 있는 사람도 들이마시면서 간접적으로 노출될 수 있다.<br>액상형 전자담배도 담배의 중독 물질인 니코틴을 포함하고 있다. 국내외에서 대마초 등의 마약을 전자담배 기구를 이용해 흡입하는 사례가 늘어나고 있다.<br>액상형 전자담배는 보통 밝은 색상이며, 니코틴이 들어있는 용액에 다양한 향료(과일, 사탕, 멘솔, 민트 등)을 첨가하여 유혹하고 있다.',
      llm: '액상형 전자담배는 일반 담배와는 달리 배터리로 작동하는 전자 기기입니다. 이 기기는 니코틴이 들어 있는 용액을 배터리로 가열해 나오는 기체(에어로졸)를 흡입하는 방식입니다. 2003년 중국에서 처음 개발되었으며, 이후 전 세계로 퍼져 청소년과 젊은 성인들 사이에서 인기를 얻고 있습니다. 2017년부터 국내에서 판매되기 시작한 궐련형 전자담배(아이코스, 글로, 릴 등)와는 다른 종류의 제품입니다.\n액상형 전자담배를 사용하는 사람은 에어로졸을 폐로 들이마시며, 이때 주변 사람들도 간접적으로 에어로졸에 노출될 수 있습니다.\n액상형 전자담배에는 중독을 일으킬 수 있는 니코틴이 포함되어 있습니다. 또한, 국내외에서 전자담배 기기를 이용해 대마초와 같은 마약을 흡입하는 사례도 점점 늘고 있습니다.\n이 전자담배는 보통 밝은 색상을 띠며, 니코틴 용액에 과일, 사탕, 멘솔, 민트 등 다양한 향료가 첨가되어 사람들의 관심을 끌고 있습니다.',
      a: '',
      b: '',
    },{
      question: '각막이란 안구의 가장 앞부분에 위치하는 검은 동자에 해당합니다. 검게 보이기 때문에 흔히 “검은 동자”라고 하지만 각막은 실제로 눈 안에 빛이 없기 때문에 검게 보일 뿐, 가장 투명한 인체조직 중의 하나입니다. 각막은 이런 투명성을 유지하기 위하여 우리 몸의 다른 장기와는 다른 몇 가지 특성을 가지고 있습니다. 그 중 하나는 혈관이 존재하지 않아 산소의 공급을 대기로부터 눈물을 통해 직접 공급받는다는 것입니다. 하지만 각막이 외상을 받거나, 심한 염증을 앓거나 혹은 선천적인 이유 등에 의해서 그 투명성을 유지하지 못하고 혼탁(투명한 각막이 뿌옇게 됨)이 생기게 되면, 시신경을 비롯한 눈의 다른 모든 기능이 정상적이라 할지라도 심각한 시력장애를 일으키게 됩니다. 이런 혼탁에 대하여 약물이나 레이저 등의 치료가 힘든 경우에는 각막을 절제해내고 기증받은 안구의 투명한 각막으로 대치하여 빛이 눈의 내부로 잘 들어갈 수 있도록 해야 합니다. 이러한 수술이 각막이식입니다. 하지만 각막 이외의 망막질환과 시신경관련 장애, 또는 어려서 발생한 각막혼탁, 사시 등에 의한 약시 등을 앓고 있는 환자는 각막이식을 하여도 그 효과가 미미합니다. 그리고 이러한 경우에 안구의 전체를 이식하는 수술은 현재의 의학기술로써는 불가능합니다. 따라서 위에서 언급한 바와 같이 눈의 창문에 해당하는 각막만을 교체하는 것이 현재 행해지고 있는 각막이식입니다.',
      answer: '각막이란 안구의 가장 앞부분에 위치하는 검은 동자에 해당합니다. 검게 보이기 때문에 흔히 “검은 동자”라고 하지만 각막은 실제로 눈 안에 빛이 없기 때문에 검게 보일 뿐, 가장 투명한 인체조직 중의 하나입니다. 각막은 이런 투명성을 유지하기 위하여 우리 몸의 다른 장기와는 다른 몇 가지 특성을 가지고 있습니다. 그 중 하나는 혈관이 존재하지 않아 산소의 공급을 대기로부터 눈물을 통해 직접 공급받는다는 것입니다. 하지만 각막이 외상을 받거나, 심한 염증을 앓거나 혹은 선천적인 이유 등에 의해서 그 투명성을 유지하지 못하고 혼탁(투명한 각막이 뿌옇게 됨)이 생기게 되면, 시신경을 비롯한 눈의 다른 모든 기능이 정상적이라 할지라도 심각한 시력장애를 일으키게 됩니다. 이런 혼탁에 대하여 약물이나 레이저 등의 치료가 힘든 경우에는 각막을 절제해내고 기증받은 안구의 투명한 각막으로 대치하여 빛이 눈의 내부로 잘 들어갈 수 있도록 해야 합니다. 이러한 수술이 각막이식입니다. 하지만 각막 이외의 망막질환과 시신경관련 장애, 또는 어려서 발생한 각막혼탁, 사시 등에 의한 약시 등을 앓고 있는 환자는 각막이식을 하여도 그 효과가 미미합니다. 그리고 이러한 경우에 안구의 전체를 이식하는 수술은 현재의 의학기술로써는 불가능합니다. 따라서 위에서 언급한 바와 같이 눈의 창문에 해당하는 각막만을 교체하는 것이 현재 행해지고 있는 각막이식입니다.',
      llm: '각막은 눈의 가장 앞쪽에 있는 투명한 조직입니다. 흔히 "검은 동자"라고 부르지만, 각막 자체는 매우 투명합니다. 단지 눈 안에 빛이 없어서 검게 보일 뿐입니다. 각막은 이 투명함을 유지하기 위해 특별한 특징을 가지고 있습니다.\n주요 특징 중 하나는 각막에는 혈관이 없어서 산소를 공기 중에서 받아들인다는 점입니다. 이때 산소는 눈물에 녹아 각막으로 전달됩니다. 만약 각막이 다치거나 염증이 생기거나 선천적인 이유로 인해 투명함을 잃고 뿌옇게 되면, 다른 눈의 기능이 정상이라 해도 시력이 크게 나빠집니다.\n각막이 뿌옇게 되어 약물이나 레이저 치료가 어려운 경우, 손상된 각막을 제거하고 기증받은 투명한 각막으로 교체하는 수술을 합니다. 이 수술을 각막이식이라고 합니다.\n하지만, 망막 질환이나 시신경 문제, 어릴 때 발생한 각막 혼탁, 사시로 인한 약시가 있는 경우에는 각막이식의 효과가 거의 없습니다. 그리고 현재의 의학기술로는 눈 전체를 이식하는 수술은 불가능합니다. 따라서 각막이식은 눈의 창문 역할을 하는 각막만 교체하는 수술입니다.',
      a: '',
      b: '',
    },{
      question: '건강기능식품은 질병의 예방, 치료가 아닌 건강 유지와 개선에 도움을 주는 식품이므로 의약품만큼의 효능을 기대하긴 어렵습니다. 의약품의 경우 제품으로 출시되기 위해서는 다수의 엄격한 임상시험을 통해 효과와 안전성이 증명되어야 합니다. 반면에 건강기능식품의 기능성의 대부분을 차지하는 생리활성 기능성은 한두 건의 소규모 인체적용시험 결과만으로도 인정될 수 있으므로 근거 수준이 높다고 볼 수는 없습니다.<br>또한 건강기능식품에는 질병 예방과 치료 효과가 없다고 밝히고 있지만, 실제로 소비자들은 단순히 건강 유지를 위해서가 아니라 질병 예방과 치료 효과를 기대하며 건강기능식품을 구입하는 경우가 많습니다. ‘혈중 콜레스테롤 개선에 도움을 줄 수 있음’, ‘혈당 조절에 도움을 줄 수 있음’, ‘혈행 개선에 도움을 줄 수 있음’과 같은 기능성 문구도 혼동을 일으킬 여지가 있습니다. 또한 허위·과대광고 역시 효과에 대한 지나친 기대를 부추길 수 있습니다. 이러한 문제를 보다 현명하게 해결하기 위한 논의가 지속적으로 필요합니다.<br>그러므로 건강기능식품의 효과에 대해 지나친 기대는 하지 않는 것이 바람직합니다. 특히 질병 상태 개선이나 치료 목적으로 건강기능식품을 섭취하는 것은 권하지 않습니다. 만성질환을 가지고 있는 경우 의약품 장기 복용의 부작용을 지나치게 우려해 꼭 복용해야 할 의약품 대신 건강기능식품을 섭취하는 경우도 있는데 이러한 일은 절대 피해야 합니다.',
      answer: '건강기능식품은 질병의 예방, 치료가 아닌 건강 유지와 개선에 도움을 주는 식품이므로 의약품만큼의 효능을 기대하긴 어렵습니다. 의약품의 경우 제품으로 출시되기 위해서는 다수의 엄격한 임상시험을 통해 효과와 안전성이 증명되어야 합니다. 반면에 건강기능식품의 기능성의 대부분을 차지하는 생리활성 기능성은 한두 건의 소규모 인체적용시험 결과만으로도 인정될 수 있으므로 근거 수준이 높다고 볼 수는 없습니다.<br>또한 건강기능식품에는 질병 예방과 치료 효과가 없다고 밝히고 있지만, 실제로 소비자들은 단순히 건강 유지를 위해서가 아니라 질병 예방과 치료 효과를 기대하며 건강기능식품을 구입하는 경우가 많습니다. ‘혈중 콜레스테롤 개선에 도움을 줄 수 있음’, ‘혈당 조절에 도움을 줄 수 있음’, ‘혈행 개선에 도움을 줄 수 있음’과 같은 기능성 문구도 혼동을 일으킬 여지가 있습니다. 또한 허위·과대광고 역시 효과에 대한 지나친 기대를 부추길 수 있습니다. 이러한 문제를 보다 현명하게 해결하기 위한 논의가 지속적으로 필요합니다.<br>그러므로 건강기능식품의 효과에 대해 지나친 기대는 하지 않는 것이 바람직합니다. 특히 질병 상태 개선이나 치료 목적으로 건강기능식품을 섭취하는 것은 권하지 않습니다. 만성질환을 가지고 있는 경우 의약품 장기 복용의 부작용을 지나치게 우려해 꼭 복용해야 할 의약품 대신 건강기능식품을 섭취하는 경우도 있는데 이러한 일은 절대 피해야 합니다.',
      llm: '건강기능식품은 질병을 예방하거나 치료하기 위한 것이 아니라, 건강을 유지하고 개선하는 데 도움을 주는 식품입니다. 따라서 의약품과 같은 효능을 기대하기는 어렵습니다. 의약품은 출시되기 전에 여러 차례의 엄격한 임상시험을 통해 효과와 안전성이 증명되어야 합니다. 반면, 건강기능식품의 기능은 소규모 인체적용시험 몇 건으로도 인정될 수 있어, 근거 수준이 높다고 보기는 어렵습니다.\n또한, 건강기능식품이 질병 예방과 치료 효과가 없다고 명시되어 있지만, 소비자들은 종종 질병 예방과 치료를 기대하며 건강기능식품을 구매합니다. 예를 들어, ‘혈중 콜레스테롤 개선에 도움을 줄 수 있음’, ‘혈당 조절에 도움을 줄 수 있음’, ‘혈행 개선에 도움을 줄 수 있음’과 같은 문구는 혼란을 줄 수 있습니다. 허위나 과장된 광고도 소비자들이 과도한 기대를 하게 만드는 원인이 됩니다. 이러한 문제를 해결하기 위한 논의가 필요합니다.\n따라서 건강기능식품의 효과에 대해 지나친 기대를 하지 않는 것이 좋습니다. 특히, 질병을 치료하거나 상태를 개선하기 위해 건강기능식품을 복용하는 것은 권장되지 않습니다. 만성질환이 있는 경우, 의약품의 장기 복용 부작용을 지나치게 걱정하여 필수적인 의약품 대신 건강기능식품을 복용하는 것은 절대 피해야 합니다.',
      a: '',
      b: '',
    },
  ];


  constructor (
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,

    private lmsService: LmsService,
    public accountService: AccountService,
  ) {
    this.lectureId = +route.snapshot.params['id'] || undefined;
    this.step = route.snapshot.params['step'] || 'overview';
    
    if (!this.accountService?.user?.permission || this.accountService.user.permission < 1) {
      this.accountService.reload_session();

      setTimeout(() => {
        if (!this.accountService?.user?.permission || this.accountService.user.permission < 1) {
          this.dialog.open(ConfirmDialogComponent, {
            width : '450px',
            data : {
              title : 'Warning',
              content : `로그인이 필요한 서비스입니다.`,
              button : [{ text : 'OK', color : '#333' }],
            },
          }).afterClosed().subscribe(() => {
            location.href = '/#/login';
          });
        }
        else {
          this.get_lectureDetail();
        }
      }, 500);
    }
    else {
      this.get_lectureDetail();
    }
  }

  
  public courseSectionList: Array<any> = [{
    name: '1차시 - 강의개요',
    data: [],
  }, {
    name: '2차시 - 이론',
    data: [],
  }, {
    name: '3차시 - 심화학습',
    data: [],
  }, {
    name: '4차시 - 실습',
    data: [],
  }];


  public get_lectureDetail() {
    this.lmsService.get_lectureDetail(this.lectureId).subscribe((res: any) => {
      if (res.status) {
        for (let i = 0; i < res.data?.length; i++) {
          let course = res.data[i];

          if (course.duration >= 3600) { 
            course.time = `${('00' + Math.floor(course.duration / 3600)).slice(-2)}:${('00' + Math.floor((course.duration % 3600) / 60)).slice(-2)}:${('00' + (course.duration % 60)).slice(-2)}`;
          } 
          else {
            course.time = `${('00' + Math.floor(course.duration / 60)).slice(-2)}:${('00' + (course.duration % 60)).slice(-2)}`;
          }

          course.min = Math.ceil(course.duration / 60);
          course.processedMin = Math.ceil(course.duration * course.progressPercent / 6000);
          this.courseSectionList[i % 4].data.push(course);
        }

        this.accountService.certificate = res.lecture;
        this.currentCourse = res.lecture;
        this.courseList = res.data;
      }
    });
  }

  public print_certificate() {
    const printWindow = window.open(`/#/certificate/${this.lectureId}`, '_blank');
    if (printWindow) {
      printWindow.onload = () => {
        setTimeout(() => {
          printWindow.print();
        }, 1000);
      };
    }
  }


  public submit_quiz () {
    this.quizSubmit = true;

    for (let i = 0; i < this.quizList.length; i++) {
      this.quizList[i].a = this.sanitizer.bypassSecurityTrustHtml(this.diff(this.quizList[i].answer, this.quizList[i].llm, true, false, false));
      this.quizList[i].b = this.sanitizer.bypassSecurityTrustHtml(this.diff(this.quizList[i].llm, this.quizList[i].answer, false, true, false));
      this.quizList[i].c = this.sanitizer.bypassSecurityTrustHtml(this.diff(this.quizList[i].answer, this.quizList[i].llm, true, true, false));
      this.quizList[i].d = this.sanitizer.bypassSecurityTrustHtml(this.diff(this.quizList[i].answer, this.quizList[i].llm, true, true, true));
    }
  }


  public diff(original: string, updated: string, showAdd: boolean, showDelete: boolean, highlight: boolean): string {
    const _original = original.replace(/\s+/g, '');
    const _updated = updated.replace(/\s+/g, '');

    const lcsMatrix = this.buildLcsMatrix(_original, _updated);
    const lcs = this.extractLcs(_original, _updated, lcsMatrix);

    let resultHtml = '';
    let i = 0, j = 0, type = 0;

    lcs.forEach(char => {
      while (original[i] !== char && i < original.length) {
        if (showDelete) {
          if (original[i] === ' ') {
            resultHtml += ' ';
          }
          else {
            if (type != 1) {
              if (type) {
                resultHtml += '</span>';
              }

              resultHtml += highlight ? 
                `<span class="wrong-word-correction">` :
                `<span style="color: red; text-decoration: line-through;">`;
              type = 1;
            }

            resultHtml += original[i];
          }
        }
        i++;
      }
      while (updated[j] !== char && j < updated.length) {
        if (showAdd) {
          if (updated[j] === ' ') {
            resultHtml += ' ';
          }
          else {
            if (type != 2) {
              if (type) {
                resultHtml += '</span>';
              }

              resultHtml += highlight ? 
                `<span class="word-correction">` :
                `<span style="color: green;">`;
              type = 2;
            }
    
            resultHtml += updated[j];
          }
        }
        j++;
      }

      if (type != 3) {
        if (type) {
          resultHtml += '</span>';
        }

        resultHtml += '<span>';
        type = 3;
      }

      resultHtml += `${char}`;
      i++;
      j++;
    });

    while (i < original.length) {
      if (showDelete) {
        if (type != 1) {
          if (type) {
            resultHtml += '</span>';
          }

          resultHtml += highlight ? 
            `<span class="wrong-word-correction">` :
            `<span style="color: red; text-decoration: line-through;">`;
          type = 1;
        }

        resultHtml += original[i];
      }
      
      i++;
    }
    while (j < updated.length) {
      if (showAdd) {
        if (type != 2) {
          if (type) {
            resultHtml += '</span>';
          }

          resultHtml += highlight ? 
            `<span class="word-correction">` :
            `<span style="color: green;">`;
          type = 2;
        }

        resultHtml += updated[j];
      }
      
      j++;
    }

    if (type) {
      resultHtml += '</span>';
    }

    return resultHtml;
  }

  private buildLcsMatrix(original: string, updated: string): number[][] {
    const lcsMatrix = Array(original.length + 1)
      .fill(null)
      .map(() => Array(updated.length + 1).fill(0));

    for (let i = 1; i <= original.length; i++) {
      for (let j = 1; j <= updated.length; j++) {
        if (original[i - 1] === updated[j - 1]) {
          lcsMatrix[i][j] = lcsMatrix[i - 1][j - 1] + 1;
        } else {
          lcsMatrix[i][j] = Math.max(lcsMatrix[i - 1][j], lcsMatrix[i][j - 1]);
        }
      }
    }
    return lcsMatrix;
  }

  private extractLcs(original: string, updated: string, lcsMatrix: number[][]): string[] {
    let i = original.length;
    let j = updated.length;
    const lcs = [];

    while (i > 0 && j > 0) {
      if (original[i - 1] === updated[j - 1]) {
        lcs.unshift(original[i - 1]);
        i--;
        j--;
      } else if (lcsMatrix[i - 1][j] > lcsMatrix[i][j - 1]) {
        i--;
      } else {
        j--;
      }
    }

    return lcs;
  }

  public generateDiff(original: string, updated: string, displayAdd: boolean, displayRemove: boolean): string {
    const originalWords = original.split(' ');
    const updatedWords = updated.split(' ');
    let diffHtml = '';

    console.log(originalWords);
    console.log(updatedWords)

    for (let i = 0; i < Math.max(originalWords.length, updatedWords.length); i++) {
      if (originalWords[i] !== updatedWords[i]) {
        if (displayRemove) {
          diffHtml += `<span style="color: red; text-decoration: line-through;">${originalWords[i] || ''}</span> `;
        }
        if (displayAdd) {
          diffHtml += `<span style="color: green;">${updatedWords[i] || ''}</span> `;
        }
      } else {
        diffHtml += `${originalWords[i] || ''} `;
      }
    }
    console.log(diffHtml);
    return diffHtml;
  }
}
