<div *ngIf="step == 'login'" class="lms-login-wrapper">
  <div class="lms-login-container">
    <div class="lms-login-box">
      <img class="lms-login-logo" src="../../../assets/images/title.jpeg" routerLink="/">
      <span class="lms-login-text">서비스 이용을 위해 가입한 계정으로 로그인해주세요</span>
      <input class="lms-login-input" type="text" placeholder="이메일" [(ngModel)]="input.signin.email">
      <input class="lms-login-input" type="password" placeholder="비밀번호" [(ngModel)]="input.signin.password">
      <div class="lms-login-button" (click)="signin()">로그인</div>
      <div class="lms-login-option">
        <div class="lms-login-option-label">
          아직 회원이 아니신가요?
          <div class="lms-login-option-link" (click)="step = 'signup'">회원가입</div>
        </div>
        <div class="lms-login-option-label">
          혹시 비밀번호가 기억나지 않으신가요?
          <div class="lms-login-option-link" (click)="step = 'reset-password'">비밀번호찾기</div>
        </div>
      </div>
    </div>
    <div class="lms-login-back" routerLink="/">
      <span>홈으로 돌아가기</span>
    </div>
  </div>
</div>


<div *ngIf="step == 'signup'" class="lms-login-wrapper">
  <div class="lms-signup-container">
    <div class="lms-signup-box">
      <img class="lms-signup-logo" src="../../../assets/images/title.jpeg" routerLink="/">
      <span class="lms-signup-text">회원 가입을 위해 아래 정보를 입력해주세요</span>
      이메일
      <input class="lms-signup-input" type="text" placeholder="이메일" [(ngModel)]="input.signup.email">
      비밀번호
      <input class="lms-signup-input" type="password" placeholder="비밀번호" [(ngModel)]="input.signup.password">
      비밀번호 재입력
      <input class="lms-signup-input" type="password" placeholder="비밀번호" [(ngModel)]="input.signup.passwordConfirm">
      이름
      <input class="lms-signup-input" type="text" placeholder="이름" [(ngModel)]="input.signup.name">
      전화번호
      <input class="lms-signup-input" type="tel" pattern="[0-9]{3}-[0-9]{3,4}-[0-9]{4}" placeholder="010-xxxx-xxxx" [(ngModel)]="input.signup.phone">
      출생연도
      <input class="lms-signup-input" type="text" maxlength="4" placeholder="4자리 숫자로 표기해주세요" [(ngModel)]="input.signup.birthYear">
      회원소속
      <input class="lms-signup-input" type="text" placeholder="이수증에 표기하실 소속을 입력해주세요." [(ngModel)]="input.signup.company">
      소속학회
      <select class="lms-signup-input" [(ngModel)]="input.signup.academy">
        <option value="외과">외과</option>
        <option value="내과">내과</option>
        <option value="안과">안과</option>
        <option value="흉부외과">흉부외과</option>
        <option value="정형외과">정형외과</option>
        <option value="가정의학과">가정의학과</option>
        <option value="정신의학과">정신의학과</option>
      </select>

      <div class="lms-signup-button" (click)="signup()">회원가입</div>
    </div>
    <div class="lms-signup-back" (click)="step = 'login'">
      <span>로그인화면으로 돌아가기</span>
    </div>
  </div>
</div>

<div *ngIf="step == 'reset-password'" class="lms-login-wrapper">
  <div class="lms-signup-container">
    <div class="lms-signup-box">
      <img class="lms-signup-logo" src="../../../assets/images/title.jpeg" routerLink="/">
      <span class="lms-signup-text">가입하신 이메일로 비밀번호 초기화 링크를 전송합니다.</span>
      <input class="lms-signup-input" type="text" placeholder="이메일" [(ngModel)]="input.resetPassword.email">
      
      <div class="lms-signup-button" (click)="resetPassword()">비밀번호 초기화</div>
    </div>
    <div class="lms-signup-back" (click)="step = 'login'">
      <span>로그인화면으로 돌아가기</span>
    </div>
  </div>
</div>