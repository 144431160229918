<app-header></app-header>
<app-banner title="공지사항" titleSize="48px" titleColor="#fff" bannerHeight="320px" bannerImage="../../../assets/images/11.webp" overlayColor="#0003"></app-banner>
<div class="content-wrapper">
  <div class="content-body" *ngIf="!id">
    <h1>공지사항</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
    <table class="lms-notice-board">
      <tr>
        <th></th>
        <th>제목</th>
        <th>작성자</th>
        <th>작성일</th>
      </tr>
      <tr *ngFor="let item of fixList" [routerLink]="'/notice/' + item.id " style="background-color: #007eff20;">
        <td><span class="lms-notice-chip">Notice</span></td>
        <td>{{item.title}}</td>
        <td>관리자</td>
        <td>{{item.created_ts | relativeDate}}</td>
      </tr>
      <tr *ngFor="let item of list" [routerLink]="'/notice/' + item.id ">
        <td>{{item.id}}</td>
        <td>{{item.title}}</td>
        <td>관리자</td>
        <td>{{item.created_ts | relativeDate}}</td>
      </tr>
    </table>
  </div>
  <div class="content-body" *ngIf="id">
    <h1>{{title}}</h1>
    <div class="lms-notice-meta">작성일 {{created_ts | relativeDate}} | 수정일 {{updated_ts | relativeDate}}</div>

    <div class="lms-notice-body" [innerHTML]="content"></div>
  </div>
</div>
<app-footer></app-footer>
