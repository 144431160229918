import { Component } from '@angular/core';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LmsService } from 'src/app/service/lms.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  public targetQnA: any = {
    name: '전체',
    list: [],
  };
  public faq: Array<any> = [];
  expandedIndex = 0;

  constructor(
    private lmsService: LmsService,
  ) { 
    this.lmsService.get_faq().subscribe((res) => {
      if(res.status) {
        this.faq = res.data;
        this.targetQnA = this.faq[1];
      }
    });
  }
}
