import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';
import { AccountService } from 'src/app/service/account.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  constructor(
    public account: AccountService,
    private dialog: MatDialog,
  ) {
    
  }

  public alert(text: string) {
    alert(text);
  }
  public logout() {
    this.account.signout().subscribe((res) => {
      if (res.status) {
        this.account.session = null;
        this.account.user = null;
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : `로그아웃 되었습니다.`,
            button : [{ text : 'OK', color : '#333' }],
          },
        }).afterClosed().subscribe(() => {
          
        });
      }
    });
  }
}
