import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {
  transform(value: string): string {
    const d = new Date();
    const diffInSeconds = Math.floor((d.getTime() - new Date(value).getTime()) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30.44);
    const diffInYears = Math.floor(diffInMonths / 12);
    
    if (diffInSeconds < 60) {
      return '방금';
    }
    if (diffInMinutes < 60) {
      return `${diffInMinutes}분 전`;
    }
    if (diffInHours < 24) {
      return `${diffInHours}시간 전`;
    }
    if (diffInDays < 7) {
      return `${diffInDays}일 전`;
    }
    if (diffInWeeks < 5) {
      return `${diffInWeeks}주일 전`;
    }
    if (diffInMonths < 12) {
      return `${diffInMonths}개월 전`;
    }

    return `${diffInYears}년 전`;
  }
}