import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StatusService } from './service/status.service';
import { AccountService } from './service/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public status: StatusService,
    public account: AccountService,
  ) {
  }

  ngOnInit(): void {
    // this.checkDevTools(); 
    this.account.reload_session();
  }

  public checkDevTools() {
    let element = new Image();
    let isOpenDevTools: boolean = false;
    let checkDevToolsHandler: any;
    
    Object.defineProperty(element, 'id', {
      get: function() {
        isOpenDevTools = true;
        // clearInterval(checkDevToolsHandler);
        document.location.href = "https://www.naver.com";
      }
    });

    checkDevToolsHandler = setInterval(() => {
      console.log(Object.defineProperties(new Error, {
        toString: {
          value() {
            (new Error).stack?.includes('toString@') && alert('Safari devtools')
          }
        },
        message: {
          get() {
            document.location.href = "https://www.naver.com";
          }
        },
      }));

      console.dir(element);
    }, 1000);
  }

  public click = {
    toggleMenu: () => {
      this.status.sidebarExpand = !this.status.sidebarExpand;

      console.log(this.status.sidebarExpand);
    },
  }
}
