<app-header></app-header>
<app-banner title="문의하기" titleSize="48px" titleColor="#fff" bannerHeight="320px" bannerImage="../../../assets/images/5.webp" overlayColor="#0003"></app-banner>
<div class="content-wrapper">
  <div class="content-body">
    <h1>문의하기</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id malesuada mauris. Donec malesuada dictum condimentum. Curabitur eget bibendum turpis. Nam et eros a massa eleifend pharetra. Morbi mollis mi nec ante facilisis maximus. Etiam dapibus dolor in diam placerat rutrum. Mauris rhoncus lorem et molestie condimentum.</p>
    <br>
    <div class="lms-user-baord-label">이메일 주소</div>
    <input class="lms-user-baord-input" type="email" placeholder="회신받을 이메일 주소를 적어주세요." [(ngModel)]="email">
    <div class="lms-user-baord-label">문의 내용</div>
    <textarea class="lms-user-baord-textarea" placeholder="문의내용을 적어주세요." rows="24" [(ngModel)]="content"></textarea>
    
    <div class="lms-user-baord-button" (click)="sendmail()">문의하기</div>
  </div>
</div>
<app-footer></app-footer>
