import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public apiServer : string = ''    // Leave it blank to use the same url on the browser, or input your API SERVER URL
  public httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
    public httpClient: HttpClient
  ) { 

  }


  public package_param(param: any) {
    let _params = new HttpParams();
    
    for (let key in param)
      _params = _params.append(key, `${param[key]}`);

    return {params: _params};
  }
}
