<h1 mat-dialog-title>{{data.username}}님의 시청기록</h1>
<div mat-dialog-content *ngIf="!data.log?.length">
  <p>시청 기록이 존재하지 않습니다.</p>
</div>
<div mat-dialog-content *ngIf="data.log?.length">
  <div class="lms-log-row" *ngFor="let log of data.log">
    <img class="lms-log-thumbnail" [src]="log.thumbnail">
    <div class="lms-log-meta">
      <div class="lms-log-title">{{log.videoName || '-'}}</div>
      <div class="lms-log-info">
        <span>{{log.category || '-'}}</span>&nbsp;>&nbsp;
        <span>{{log.courseName || '-'}}</span><br>
      </div>
      <div class="lms-log-info">
        <i class="fa fa-user"></i>&nbsp;
        <span>{{log.lecturer || '-'}}</span>&nbsp;|&nbsp;
        <i class="fa fa-history"></i>&nbsp;
        <span>{{log.ts | relativeDate}}</span>&nbsp;|&nbsp;
        <i class="fa fa-percent"></i>&nbsp;
        <span>{{log.processPercent || '0'}}%</span>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <div class="buttons">
    <button *ngFor="let button of data.button" mat-button [ngStyle]="{'color':button.color}" [mat-dialog-close]="button.text" (click)="updateClosedReason(button.text)">{{button.text}}</button>
    <!-- <button mat-button [mat-dialog-close]="button.text" cdkFocusInitial>Ok</button> -->
  </div>
</div>
