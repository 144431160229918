import { Component } from '@angular/core';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

import { AccountService } from 'src/app/service/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  public step = 'login';
  public input = {
    signin: {
      email: '',
      password: '',
    },
    signup: {
      email: '',
      name: '',
      password: '',
      passwordConfirm: '',
      birthYear: '',
      phone: '',
      company: '',
      academy: '',
    },
    resetPassword: {
      email: '',
    }
  }
  public validation = {
    signup : {
      name: false, 
      email: false,
      password: false,
      nameCheck: () => {
        this.validation.signup.name = (this.input.signup.name.length >= 4);
      },
      emailCheck: () => {
        this.validation.signup.email = (this.input.signup.email.search(/^[0-9a-zA-Z]([\-.\w]*[0-9a-zA-Z\-_+])*@([0-9a-zA-Z][\-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}$/g) !== -1);
      },
      passwordCheck: () => {
        this.validation.signup.password = (this.input.signup.password.length >= 8);
      },
    },
    signin : {
        all : false,
        allCheck : () => {
            this.validation.signin.all = (this.input.signin.email.length > 0 && this.input.signin.password.length > 0);
        },
    },
  }


  constructor (
    public dialog: MatDialog,
    private accountService: AccountService,
  ) {
  }

  public signin () {
    this.accountService.signin(
      this.input.signin.email,
      this.input.signin.password,
    ).subscribe((res) => {
      if(res.status) {
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : `<b>${res?.data?.name}</b>님 환영합니다.`,
            button : [{ text : 'OK', color : '#333' }],
          },
        }).afterClosed().subscribe(() => {
          location.href = '/';
        });
        console.log(res);
      }
      else {
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : res.message,
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }
    });
  }
  public signup () {
    this.accountService.signup(
      this.input.signup.email, 
      this.input.signup.name, 
      this.input.signup.password,
      this.input.signup.phone,
      this.input.signup.birthYear,
      this.input.signup.company,
      this.input.signup.academy,
    ).subscribe((res) => {
      if(res.status) {
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : `회원가입이 완료되었습니다.<br>서비스 이용을 위해 <b>${this.input.signup.email}</b>로 전송된 메일을 인증해주세요.`,
            button : [{ text : 'OK', color : '#333' }],
          },
        }).afterClosed().subscribe(() => {
          this.step = 'login';
          this.input.signup.email = '';
          this.input.signup.name = '';
          this.input.signup.password = '';
          this.input.signup.passwordConfirm = '';
          this.input.signup.birthYear = '';
          this.input.signup.phone = '';
          this.input.signup.company = '';
          this.input.signup.academy = '';
        });
      }
      else {
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Warning',
            content : res.message,
            button : [{ text : 'OK', color : '#333' }],
          },
        });
      }
    });
  }

  public resetPassword () {
    this.accountService.reset_password(this.input.resetPassword.email).subscribe((res) => {
      if (res.status) {
        this.dialog.open(ConfirmDialogComponent, {
          width : '450px',
          data : {
            title : 'Notice',
            content : `비밀번호 재설정을 위해 <b>${this.input.resetPassword.email}</b>로 전송된 메일을 확인해주세요.`,
            button : [{ text : 'OK', color : '#333' }],
          },
        }).afterClosed().subscribe(() => {
          this.step = 'login';
          this.input.resetPassword.email = '';
        });
      }
    });
  } 
}
