import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';
import { AccountService } from 'src/app/service/account.service';
import { LmsService } from 'src/app/service/lms.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent {
  public courseCategoryList: Array<any> = [];
  public selectedCourse: number = 0;
  public lectureList: Array<any> = [];
  
  constructor (
    private dialog: MatDialog,
    private accountService: AccountService,
    private lmsService: LmsService,
  ) {
    this.lmsService.get_lectureCategory().subscribe((res: any) => {
      if (res.status) {
        this.courseCategoryList = res.data;

        if (this.courseCategoryList?.length) {
          this.click_course(this.courseCategoryList[0].id);      
        }
      }
    });
  }

  public click_course(id: number) {
    this.selectedCourse = id;

    this.lmsService.get_lectureList(id).subscribe((res: any) => { 
      if (res.status) {
        this.lectureList = res.data;
      }
    });
  }

  public open_course(id: number) {
    if (!this.accountService?.user?.permission || this.accountService.user.permission < 1) {    
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Warning',
          content : `로그인이 필요한 서비스입니다.`,
          button : [{ text : 'OK', color : '#333' }],
        },
      }).afterClosed().subscribe(() => {
        location.href = '/#/login';
      });
    }
    else {
      location.href = `/#/course-detail/${id}`;
    }
  }
}
