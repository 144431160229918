import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LmsService } from 'src/app/service/lms.service';

@Component({
  selector: 'app-course-viewer',
  templateUrl: './course-viewer.component.html',
  styleUrls: ['./course-viewer.component.scss']
})
export class CourseViewerComponent {
  @ViewChild('video', { static: true }) video!: ElementRef<HTMLVideoElement>;

  public videoId?: number = 0;
  public courseId?: number = 0;
  public course: any = {};
  private interval: number = 8000;
  
  constructor (
    private route: ActivatedRoute,
    private lmsService: LmsService,
  ) {
    this.videoId = +route.snapshot.params['id'] || undefined;
    this.courseId = +route.snapshot.params['cid'] || undefined;

    this.lmsService.get_lectureVideo(this.videoId).subscribe((res: any) => {
      if (res.status) {
        this.course = res.data;

        const seek_startTime = () => {
          if (this.video.nativeElement.duration) {
            if (this.video.nativeElement.currentTime + this.interval < this.video.nativeElement.duration) {
              this.video.nativeElement.currentTime = res.second;
            }
          }
          else {
            setTimeout(seek_startTime, 300);
          }
        }

        seek_startTime();
      }
    });

    setInterval(() => {
      if (this.video.nativeElement.paused === false) {
        console.log(this.video.nativeElement.currentTime);

        this.lmsService.watch_lectureVideo(this.videoId || 0, this.video.nativeElement.currentTime, this.interval).subscribe((res: any) => {
          console.log(res);
        });
      }
    }, this.interval);
  }

  public back() {
    if (this.courseId) {
      location.href = `/#/course-detail/${this.courseId}/list`;
    }
    else {
      history.back();
    }
  }
}
