<div class="certificate-container" id="certificate">
  <div class="certificate-paper">
    <img class="certificate-logo" src="../../../assets/images/certificate_logo.png">
    <p class="certificate-serial">제 2024-0000000000호</p>
    <p class="certificate-title">수 료 증</p>
    <table>
      <tr>
        <th>이름</th>
        <th>{{account.user?.name}}</th>
      </tr>
      <tr>
        <td>출생연도</td>
        <td>{{account.user?.birthYear}}</td>
      </tr>
      <tr>
        <td>소속</td>
        <td>{{account.user?.company}}</td>
      </tr>
      <tr>
        <td>소속학회</td>
        <td>{{account.user?.academy}}</td>
      </tr>
      <tr>
        <td>과정</td>
        <td>{{account.certificate.name}}</td>
      </tr>
      <tr>
        <td>기간</td>
        <td>2024-01-01 ~ 2024-12-31</td>
      </tr>
      <tr>
        <td>수료일자</td>
        <td>2024-12-31</td>
      </tr>
      <tr>
        <td>교육시간</td>
        <td>{{hours}} 시간</td>
      </tr>
    </table>

    <p class="certificate-content">
      위 사람은 질병관리청 국가건강정보포털<br>
      정보 집필 및 감수를 위한 소정의<br>
      교육과정을 마쳤으므로 이 증서를 수여합니다.
    </p>

    <p class="certificate-date">{{date | date:'YYYY-MM-dd'}}</p>
    <p class="certificate-bottom">질병관리청장</p>
    <img class="certificate-sign" src="../../../assets/images/signature.png">
  </div>
</div>