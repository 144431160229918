<app-header></app-header>
<app-banner title="자주묻는 질문" titleSize="48px" titleColor="#fff" bannerHeight="320px" bannerImage="../../../assets/images/6.webp" overlayColor="#0003"></app-banner>
<div class="content-wrapper">
  <div class="content-body">

    <div class="lms-faq-header-wrapper"> 
      <div class="lms-faq-header">
        <span class="lms-faq-header-item" *ngFor="let item of faq" (click)="targetQnA = item" [ngClass]="{'active': targetQnA.name == item.name}">{{item.name}}</span>
      </div>
    </div>

    <cdk-accordion class="lms-faq">
      <cdk-accordion-item
        *ngFor="let qna of targetQnA.list; let index = index;"
        #accordionItem="cdkAccordionItem"
        class="lms-faq-item"
        role="button"
        tabindex="0"
        [attr.id]="'accordion-header-' + index"
        [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body-' + index">
        <div class="lms-faq-item-header" (click)="accordionItem.toggle()">
          {{ qna.question }}
          <span class="lms-faq-item-description">
            {{ accordionItem.expanded ? '➖' : '➕' }}
          </span>
        </div>
        <div
          class="lms-faq-item-body"
          role="region"
          [style.display]="accordionItem.expanded ? '' : 'none'"
          [attr.id]="'accordion-body-' + index"
          [attr.aria-labelledby]="'accordion-header-' + index"
          [innerHTML]="qna.answer">
        </div>
      </cdk-accordion-item>
    </cdk-accordion>
  </div>
</div>
<app-footer></app-footer>
