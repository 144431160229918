
<div class="lms-header-wrapper">
  <div class="lms-header">
    <img class="lms-header-logo" src="../../../assets/images/title.jpeg" routerLink="/">
    <span class="lms-header-menu">
      <span class="lms-header-menu-item">
        <span routerLink="/introduction">소개</span>
        <ul>
          <li routerLink="/introduction">인삿말</li>
          <li routerLink="/map">찾아오시는 길</li>
        </ul>
      </span>
      <span class="lms-header-menu-item" routerLink="/course-list">수강신청</span>
      <span class="lms-header-menu-item">
        <span routerLink="/notice">공지사항</span>
        <ul>
          <li routerLink="/notice">공지사항</li>
          <li routerLink="/faq">자주묻는 질문</li>
          <li routerLink="/user-baord">문의하기</li>
        </ul>
      </span>
      <span class="lms-header-menu-item" *ngIf="account?.user?.permission >= 5" style="color: crimson;">
        <span routerLink="/admin">관리자</span>
        <ul>
          <li routerLink="/admin/user">사용자 관리</li>
          <li routerLink="/admin/lecture">강의 관리</li>
          <li routerLink="/admin/contact">문의 내역</li>
        </ul>
      </span>
    </span>
    <span *ngIf="!account?.session?.id" routerLink="/login" class="lms-header-primary-button">로그인</span>
    <!-- <span *ngIf="account?.session?.id" (click)="logout()" class="lms-header-primary-button">로그아웃</span> -->
  
    <div *ngIf="account?.session?.id" class="lms-header-secondary-container">
      <span class="lms-header-secondary-button" routerLink="/mypage">
        <i class="fa fa-user"></i> &nbsp;내 정보
      </span>
      <span class="lms-header-secondary-button" (click)="logout()">
        <i class="fa fa-sign-out"></i> &nbsp;로그아웃
      </span>
    </div>
  </div>
</div>
