import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';
import { LmsService } from 'src/app/service/lms.service';

@Component({
  selector: 'app-user-board',
  templateUrl: './user-board.component.html',
  styleUrls: ['./user-board.component.scss']
})
export class UserBoardComponent {
  public email: string = '';
  public content: string = '';

  constructor (
    private dialog: MatDialog,
    public lmsService: LmsService,
  ) {
  }

  public sendmail(): any {
    const emailReg = /^[0-9a-zA-Z]([\-.\w]*[0-9a-zA-Z\-_+])*@([0-9a-zA-Z][\-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9}$/g;

    if (emailReg.test(this.email) == false) {
      return this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Warning',
          content : `이메일 양식이 올바르지 않습니다.`,
          button : [{ text : 'OK', color : '#333' }],
        },
      });
    }
    if (this.content?.length < 8) {
      return this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Warning',
          content : `문의 내용을 최소 8자 이상 입력해주세요.`,
          button : [{ text : 'OK', color : '#333' }],
        },
      });
    }

    this.lmsService.send_contact(this.email, this.content).subscribe((res: any) => {
      this.content = '';
      this.email = '';
      
      return this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'Notice',
          content : `문의 내용이 접수되었습니다.`,
          button : [{ text : 'OK', color : '#333' }],
        },
      });
    });
  }

}
