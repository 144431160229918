import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LmsService } from 'src/app/service/lms.service';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  public id?: number;
  public title: string = '';
  public content: string = '';
  public created_ts: string = '';
  public updated_ts: string = '';
  public list: Array<any> = [];
  public fixList: Array<any> = [];

  constructor(
    private route: ActivatedRoute,
    private lmsService: LmsService,
  ) { 
    this.id = +route.snapshot.params['id'] || undefined;

    this.lmsService.get_notice(this.id).subscribe((res) => {
      if (res.status) {
        if (this.id) {
          this.title = res.title;
          this.content = res.content;
          this.created_ts = res.created_ts;
          this.updated_ts = res.updated_ts;
        }
        else {
          this.list = res.list;
          this.fixList = res.fix;
        }
        
        console.log(res);
      }
    });
  }
}
