<app-header></app-header>
<!-- <app-banner title="Introduction" titleSize="64px" titleColor="#fff" bannerHeight="480px" bannerImage="../../../assets/images/3.webp" overlayColor="#0003"></app-banner> -->
<div class="content-wrapper">
  <div class="content-body">

    <h1>Introduction</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>

    <div class="lms-introduction-row">
      <div class="lms-introduction-left">
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
      </div>
      <img class="lms-introduction-image" src="../../../assets/images/title.jpeg">
    </div>
    <div class="lms-introduction-row">
      <img class="lms-introduction-image" src="../../../assets/images/1.webp">
      <div class="lms-introduction-right">
        <h1>Section 1</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id malesuada mauris. Donec malesuada dictum condimentum. Curabitur eget bibendum turpis. Nam et eros a massa eleifend pharetra.</p>
      </div>
    </div>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id malesuada mauris. Donec malesuada dictum condimentum. Curabitur eget bibendum turpis. Nam et eros a massa eleifend pharetra. Morbi mollis mi nec ante facilisis maximus. Etiam dapibus dolor in diam placerat rutrum. Mauris rhoncus lorem et molestie condimentum.</p>

    <div class="lms-introduction-row">
      <div class="lms-introduction-left">
        <h1>Section 2</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
        <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus id malesuada mauris. Donec malesuada dictum condimentum. Curabitur eget bibendum turpis. Nam et eros a massa eleifend pharetra. Morbi mollis mi nec ante facilisis maximus. Etiam dapibus dolor in diam placerat rutrum. Mauris rhoncus lorem et molestie condimentum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
      </div>
      <img class="lms-introduction-image" src="../../../assets/images/2.webp">
    </div>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eu consequat mauris. Etiam imperdiet vestibulum auctor. Donec venenatis euismod ante id semper. Maecenas nec risus id lorem ultricies cursus non vitae orci. Sed et nulla eget massa porttitor venenatis. Mauris ac purus ac mi condimentum viverra in at erat. Nulla facilisi. In vitae diam ullamcorper, cursus diam ac, porta dui. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Aliquam sollicitudin euismod mi, vel auctor dolor luctus hendrerit. Cras ut eros volutpat, egestas sapien quis, aliquet turpis.</p>
    <h1 style="text-align: center; margin: 72px 0 48px 0;">
      대표
      <img class="lms-introduction-image" src="../../../assets/images/signature.png" style="height: 100px; margin: -35px 0px;">
    </h1>
  </div>
</div>
<app-footer></app-footer>