import { Injectable } from '@angular/core';

import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { HttpService } from './http.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class LmsService {

  constructor(
    private httpService: HttpService,
  ) { 

  }

  public get_faq(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/faq`);
  }
  public get_notice(id?: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/notice${id ?  '/' + id : ''}`);
  }
  public write_notice(title: string, content: string, isFixed: boolean = false): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/lms/notice`, {
      title, content, isFixed,
    });
  }
  public modify_notice(id: number, title: string, content: string, isFixed: boolean = false): Observable<any> {
    return this.httpService.httpClient.patch<any>(`${this.httpService.apiServer}/api/v1/lms/notice/${id}`, {
      title, content, isFixed,
    });
  }
  public delete_notice(id: number): Observable<any> {
    return this.httpService.httpClient.delete<any>(`${this.httpService.apiServer}/api/v1/lms/notice/${id}`);
  }
  public get_lectureCategory(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/lecture/list`);
  }
  public get_lectureList(id: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/lecture/list/${id}`);
  }
  public get_lectureDetail(id?: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/lecture/course/${id}`);
  }
  public get_lectureVideo(id?: number): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/lms/lecture/video/${id}`);
  }
  public watch_lectureVideo(id: number, second: number, interval: number): Observable<any> {
    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/lms/lecture/video/${id}`, {
      second, interval,
    });
  }




  public send_contact(email: string, content: string): Observable<any> {
    let data = {email, content};

    return this.httpService.httpClient.post<any>(`${this.httpService.apiServer}/api/v1/contact`, data);
  }


  public get_contactList(): Observable<any> {
    return this.httpService.httpClient.get<any>(`${this.httpService.apiServer}/api/v1/contact`);
  }
}
