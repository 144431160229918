import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './page/landing-page/landing-page.component';
import { IntroductionComponent } from './page/introduction/introduction.component';
import { IntroductionMapComponent } from './page/introduction-map/introduction-map.component';
import { CourseListComponent } from './page/course-list/course-list.component';
import { CourseDetailComponent } from './page/course-detail/course-detail.component';
import { CourseViewerComponent } from './page/course-viewer/course-viewer.component';
import { NoticeComponent } from './page/notice/notice.component';
import { FaqComponent } from './page/faq/faq.component';
import { UserBoardComponent } from './page/user-board/user-board.component';
import { LoginComponent } from './page/login/login.component';
import { AdminComponent } from './page/admin/admin.component';
import { CertificateComponent } from './page/certificate/certificate.component';
import { MypageComponent } from './page/mypage/mypage.component';

const routes: Routes = [
  { path: '', component: LandingPageComponent },
  { path: 'landing', component: LandingPageComponent },
  { path: 'introduction', component: IntroductionComponent },
  { path: 'map', component: IntroductionMapComponent },
  { path: 'course-list', component: CourseListComponent },
  { path: 'course-detail/:id', component: CourseDetailComponent },
  { path: 'course-detail/:id/:step', component: CourseDetailComponent },
  { path: 'course-viewer/:id', component: CourseViewerComponent },
  { path: 'course-viewer/:id/:cid', component: CourseViewerComponent },
  { path: 'notice', component: NoticeComponent },
  { path: 'notice/:id', component: NoticeComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'user-baord', component: UserBoardComponent },
  { path: 'login', component: LoginComponent },
  { path: 'mypage', component: MypageComponent },
  { path: 'certificate', component: CertificateComponent },
  { path: 'certificate/:id', component: CertificateComponent },
  
  { path: 'admin', component: AdminComponent },
  { path: 'admin/:id', component: AdminComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
