import { Injectable } from '@angular/core';
import * as crypto from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public lastCategory: string = '';

  constructor(
  ) { 

  }
  
  public sha256(str: string) {
    return crypto.SHA256(str);
  }

  public sha256Base64(str: string) {
    return crypto.enc.Base64.stringify(this.sha256(str));
  }
}
