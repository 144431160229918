import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  @Input() title!: string;
  @Input() titleSize!: string;
  @Input() titleColor!: string;
  @Input() bannerImage!: string;
  @Input() bannerHeight!: string;
  @Input() overlayColor!: string;

}
