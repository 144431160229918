import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { ViewlogDialogComponent } from 'src/app/dialog/viewlog-dialog/viewlog-dialog.component';
import { AccountService } from 'src/app/service/account.service';
import { LmsService } from 'src/app/service/lms.service';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm-dialog/confirm-dialog.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
];


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements AfterViewInit {
  public lectureId?: number = 1;
  public courseList: Array<any> = [];
  public currentCourse: any = {};
  public step: string = 'user';
  
  public userList: Array<any> = [];
  public contactList: Array<any> = [];

  public notice: any = {
    id: 0,
    title: '',
    content: '',
    created_ts: '',
    updated_ts: '',
    isFixed: false,
    write: false,
    edit: false,
    list: [],
    fixList: [],

    new: () => {
      this.notice.write = true;
      this.notice.edit = false;
      this.notice.id = 0;
      this.notice.title = '';
      this.notice.content = '';
      this.notice.isFixed = false;
    },
    load: () => {
      this.lmsService.get_notice().subscribe((res) => {
        if (res.status) {
          this.notice.list = res.list;
          this.notice.fixList = res.fix;
        }
      });
    },
    open: (notice: any) => {
      this.lmsService.get_notice(notice.id).subscribe((res) => {
        this.notice.write = false;
        this.notice.edit = true;
        this.notice.id = res.id;
        this.notice.title = res.title;
        this.notice.content = res.content;
        this.notice.created_ts = res.created_ts;
        this.notice.updated_ts = res.updated_ts;
        this.notice.isFixed = res.isFixed;
      });
    },
    post: () => {
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'System',
          content : `공지를 게시하시겠습니까?`,
          button : [{ text : 'OK', color : '#007eff' }, { text : 'Cancel', color : '#333' }],
        },
      }).afterClosed().subscribe((res) => {
        if (res == 'OK') {
          this.lmsService.write_notice(this.notice.title, this.notice.content, this.notice.isFixed).subscribe((res) => {
            this.notice.close();
          });
        }
      });
    },
    modify: () => {
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'System',
          content : `공지를 수정하시겠습니까?`,
          button : [{ text : 'OK', color : '#007eff' }, { text : 'Cancel', color : '#333' }],
        },
      }).afterClosed().subscribe((res) => {
        if (res == 'OK') {
          this.lmsService.modify_notice(this.notice.id, this.notice.title, this.notice.content, this.notice.isFixed).subscribe((res) => {
            this.notice.close();
          });
        }
      });
    },
    delete: () => {
      this.dialog.open(ConfirmDialogComponent, {
        width : '450px',
        data : {
          title : 'System',
          content : `공지를 <b style="color: red;">삭제</b>하시겠습니까?`,
          button : [{ text : 'Delete', color : '#ff0000' }, { text : 'Cancel', color : '#333' }],
        },
      }).afterClosed().subscribe((res) => {
        if (res == 'Delete') {
          this.lmsService.delete_notice(this.notice.id).subscribe((res) => {
            this.notice.close();
          });
        }
      });
      
    },
    close: () => {
      this.notice.title = '';
      this.notice.content = '';
      this.notice.isFixed = false;
      this.notice.write = false;
      this.notice.edit = false;
      this.notice.load();
    },
  }


  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);

  @ViewChild(MatSort) sort!: MatSort;


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: any) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  
  constructor (
    private dialog: MatDialog,
    private route: ActivatedRoute,

    private _liveAnnouncer: LiveAnnouncer,

    private lmsService: LmsService,
    private accountService: AccountService,
  ) {
    this.step = route.snapshot.params['id'] || this.step;

    this.lmsService.get_lectureDetail(this.lectureId).subscribe((res) => {
      if (res.status) {
        for (let course of res.data) {
          course.time = `${('00' + Math.floor(course.duration / 60)).slice(-2)}:${('00' + (course.duration % 60)).slice(-2)}`;
        }

        this.currentCourse = res.lecture;
        this.courseList = res.data;
      }
      console.log(res);
    });

    this.accountService.get_alluser(0, 1000, '1970-01-01 00:00:00').subscribe((res) => {
      console.log(res);
      if (res.status) {
        this.userList = res.data;
      }
    });
    this.lmsService.get_contactList().subscribe((res) => {
      if(res.status) {
        this.contactList = res.data;
      }
    });

    this.notice.load();
  }

  public open_viewLog(user: any) {
    this.accountService.get_viewLog(user.id).subscribe((res) => {
      this.dialog.open(ViewlogDialogComponent, {
        width : '600px',
        data : {
          username: user?.name,
          log: res.data,
          button: [{ text : 'OK', color : '#333' }],
        },
      });
    });
  }
}
