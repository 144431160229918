<app-header></app-header>
<div class="content-wrapper">
  <div class="content-body">
    <h1>교육과정 목록</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eu lectus nec elit pellentesque tempor. Phasellus finibus eu enim id facilisis. Nam vel neque egestas, rhoncus erat ac, porta enim. Fusce tempor rutrum suscipit. Donec pretium sem id convallis sodales. Donec et elementum metus. Integer sed porttitor dolor, ac vulputate metus. In et arcu ultrices, aliquet libero et, sollicitudin velit.</p>

    <div class="lms-course-list-wrapper">
      <div class="lms-course-list-sidebar">
        <div class="lms-course-list-sidebar-item" *ngFor="let category of courseCategoryList" 
             [ngClass]="{'active': selectedCourse ==  category.id}" (click)="click_course(category.id)">
            {{category.name}}
        </div>
      </div>
      <div class="lms-course-list-gridview">
        <div class="lms-course-list-card" *ngFor="let lecture of lectureList" (click)="open_course(lecture.id)">
          <img [src]="lecture.thumbnail">
          <div class="lms-course-list-card-meta">
            <div class="lms-course-list-card-title">
              {{lecture.name}}
              <span class="lms-course-list-card-time">{{lecture.hours}}시간</span>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>
<app-footer></app-footer>
