import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StatusService } from 'src/app/service/status.service';

@Component({
  selector: 'app-viewlog-dialog',
  templateUrl: './viewlog-dialog.component.html',
  styleUrls: ['./viewlog-dialog.component.scss']
})
export class ViewlogDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ViewlogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private status: StatusService,
  ) { 
    this.status.isOpenDialog = true;

    console.log(data);
  }

  ngOnDestroy(): void {
    this.status.isOpenDialog = false;
  }
  updateClosedReason(reason: string): void {
    this.data.closedReason = reason;
  }
  close(): void {
    this.dialogRef.close();
  }
}
